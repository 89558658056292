.box {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 12.5vh;
}

.temperature {
  font-size: min(max(8vw, 100px), 150px);
  font-weight: 800;
  color: #000;
  text-shadow: 3px 3px 0.1px #ffffff;
}

.city {
  padding-top: 5%;
  font-size: min(max(2vw, 25px), 30px);
  text-shadow: 1px 1px 0.3px #ffffff;
  font-weight: 600;
  font-style: italic;
}
